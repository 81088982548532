@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Volkhov:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Volkhov", serif;
  color: #14183e;
}
p {
  margin-bottom: 20px;
  line-height: 26px;
}
body {
  font-family: "Poppins", sans-serif;
  line-height: 1.5;
  color: #5e6282;
  background-color: #fffefe;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
a {
  text-decoration: none;
}
.logo img {
  transition: all 0.5s linear;
}
.sticky  .logo img {
  max-width: 100px;
}
.header_nav {
  padding: 25px 0;
  transition: all 0.3s linear;
  z-index: 99;
}
.header_menu li a {
  color: #212832;
}
.homeBannerWrap .slick-arrow {
  display: none !important;
}
.bg_holder {
  position: absolute;
  width: 100%;
  min-height: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  will-change: transform, opacity, filter;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-repeat: no-repeat;
  z-index: 0;
}
.hero-title {
  font-weight: 700;
  font-size: 3.6rem;
  color: #181e4b;
  margin-bottom: 5px;
  position: relative;
  z-index: 1;
}
.hero-title:before {
  content: "";
  position: absolute;
  left: 0;
  top: 48px;
  width: 20rem;
  height: 0.8125rem;
  background-image: url("../images/shape.svg");
  background-size: contain;
  z-index: -1;
}

.btn {
  font-size: 16px;
  background: #006380;
  border: none;
  position: relative;
  color: #f0f0f0;
  cursor: pointer;
  padding: 13px 26px;
  border-radius: 10px;
}
.btn:hover {
  color: #fff;
  background-color: teal;
}

.destination_card img,
.shadow-hover {
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.shadow-hover:hover {
  box-shadow: 0 6.25rem 5rem 0 rgba(0, 0, 0, 0.02),
    0 4.05063rem 2.92813rem 0 rgba(0, 0, 0, 0.0152),
    0 2.4075rem 1.5925rem 0 rgba(0, 0, 0, 0.0121),
    0 1.25rem 0.8125rem 0 rgba(0, 0, 0, 0.01),
    0 0.50938rem 0.4075rem 0 rgba(0, 0, 0, 0.0079),
    0 0.11563rem 0.19687rem 0 rgba(0, 0, 0, 0.0048);
}
.service-card {
  position: relative;
  display: flex;
  flex-direction: column;
  border: none;
  background-color: #fffefe;
  border-radius: 40px;
  padding: 20px;
}
.service-card h3 {
  font-family: "Poppins", sans-serif;
  color: #14183e;
  font-weight: 600;
}
.services {
  padding: 80px 0 50px;
}
.service-card:before {
  content: "";
  position: absolute;
  left: 0.625rem;
  bottom: 0.625rem;
  width: 6.25rem;
  height: 6.25rem;
  background-color: teal;
  border-radius: 1.875rem 0 0.625rem 0;
  z-index: -1;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.service-card:hover:before {
  left: -1.875rem;
  bottom: -2.1875rem;
}
.section_heading {
  text-align: center;
  margin-bottom: 50px;
}
.section_heading h2 {
  font-size: 3.24732rem;
  color: #14183e;
}

.header_nav {
  background-color: rgba(249, 250, 253, 0.6);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
}
.header_nav.scrolled {
  padding: 15px 0;
  box-shadow: 0 1px 25px -1px rgba(0, 0, 0, 0.1);
  background-color: rgba(249, 250, 253, 0.9);
}
.destination {
  padding: 80px 0;
}

.destination_card {
  display: block;
  border-radius: 30px;
  overflow: hidden;
  color: #5e6282;
}
.destination_card:hover h4 {
  color: teal;
}
.destination_card:hover img {
  transform: scale(1.1);
}
.shadow_custom {
  box-shadow: -2px -2rem 5rem 0 rgba(0, 0, 0, 0.02),
    0px 0rem 2.92813rem 0 rgba(0, 0, 0, 0.0152),
    0 2.4075rem 1.5925rem 0 rgba(0, 0, 0, 0.0121),
    0 1.25rem 0.8125rem 0 rgba(0, 0, 0, 0.01),
    0 0.50938rem 0.4075rem 0 rgba(0, 0, 0, 0.0079),
    0 0.11563rem 0.19687rem 0 rgba(0, 0, 0, 0.0048);
}

.slick-prev:before,
.slick-next:before {
  display: none;
}
.slick-prev,
.slick-next {
  width: 35px;
  height: 35px;
  border-radius: 8px;
  background: url(../images/angle_next.svg) no-repeat center center / cover;
}
.slick-prev {
  transform: rotate(180deg);
}
.slick-prev:hover,
.slick-prev:active,
.slick-prev:focus,
.slick-next:active,
.slick-next:focus,
.slick-next:hover {
  opacity: 1;
  background: url(../images/angle_next.svg) no-repeat center center / cover;
}
.slick-prev {
  left: -50px;
}
.text-secondary {
  color: #5e6282 !important;
}
.slick-next {
  right: -50px;
}

.icon-item {
  border-radius: 50%;
  color: #080809;
  background-color: #eee;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  height: 2.5rem;
  width: 2.5rem;
  border: 0;
  font-size: 0.875rem;
}
.z-index--1 {
  z-index: -1;
}
.booking_card,
.booking_card img {
  border-radius: 1.5rem;
}
.booking {
  padding: 80px 0;
}
.newsletter_box {
  background-color: darkcyan;
  border-radius: 129px 20px 20px 20px;
  padding: 60px 40px;
}

.form-little-squirrel-control {
  border-radius: 0;
  background-color: #ffffff;
  border-radius: 10px;
  color: #39425d;
  padding: 1rem 1rem 1rem 2.7rem;
  border: none;
  font-size: 14px;
}
.input-group-icon .input-box-icon {
  position: absolute;
  top: 50%;
  left: 1rem;
  color: #212529;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.social_media a {
  width: 50px;
  height: 50px;
  color: #080809;
}
.footer .social_media a:hover {
  background-color: teal;
  color: #fff;
}
.cards_img {
  max-width: 170px;
}
.footer {
  padding: 80px 0 30px;
}
.footer a {
  color: #080809;
}
.footer a:hover {
  color: teal;
}
.hero-img {
  width: 105%;
  margin-left: -5rem;
}
.me-10 {
  margin-right: 10rem !important;
}

.banner_main_caption {
  width: 600px;
  height: 600px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 100%;
  padding: 110px 90px 90px;
  outline: 50px solid rgba(255, 255, 255, 0.4);
}
.discound {
  bottom: 20px;
  background: #008b8b;
  color: #fff;
  padding: 8px;
  right: 0;
  border-radius: 6px 0 0 6px;
}

.single__count .day__count {
  position: relative;
  z-index: 1;
}
.single__count .day__count::before {
  position: absolute;
  left: 50%;
  top: 0px;
  z-index: -1;
  height: 100%;
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  border-left-width: 1px;
  border-style: dashed;
  --tw-border-opacity: 1;
  border-color: teal;
  --tw-content: "";
  content: var(--tw-content);
}
.single__count:last-child .day__count::before {
  display: none;
}
.nav-tabs .nav-link {
  border-width: 0;
  border-bottom: 1px solid transparent;
  color: #979797;
  padding: 15px 50px;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border-bottom-color: #006380;
  color: #006380;
}

.header_menu li a.active {
  color: #008b8b;
  border-bottom-color: #008b8b;
}

.inner_banner {
  padding: 300px 0;
}